import Layout from "@/containers/layout"
import Page from "../components/Page"

const NotFoundPage = () => {
  return (
    <Layout>
      <Page fullpage paddingtop>
        <title>Not found</title>
        <h1>Page not found</h1>
        <br />
        <p>
          We couldn’t find what you were looking for.
        </p>
      </Page>
    </Layout>
  )
}

export default NotFoundPage
