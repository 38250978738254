import * as styles from './Page.module.css';

const Page = (props) => {
  const { paddingtop, center, relative, color, fullpage } = props;
  return (
    <div
      className={[
        styles.page,
        center && styles.center,
        paddingtop && styles.paddingtop,
        relative && styles.relative,
        color && styles[color],
        fullpage && styles.fullpage
      ].join(' ')}
    >
      {props.children}
    </div>
  );
};

export default Page;
